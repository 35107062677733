import type { FC } from "react";
import styled, { css } from "styled-components";

import arrowIcon from "src/assets/icons/previous-to-new-ship-to-arrow.svg";
import { GridElement, SvgIcon } from "src/components";
import { maxWidthBreakpoint } from "src/theme";
import { CopyTiersLayoutAreas } from "./CopyTiersLayout";

export const Arrow: FC = () => (
    <GridElement gridArea={CopyTiersLayoutAreas.arrow} justifySelf="center" alignSelf="center">
        <RotatingIcon icon={arrowIcon} />
    </GridElement>
);

const RotatingIcon = styled(SvgIcon)`
    ${maxWidthBreakpoint.tablet(css`
        transform: rotate(90deg);
    `)};
`;
