import { type FC, useState } from "react";

import { useGetProductPackageQuery } from "src/api";
import { Drawer, Icon, LoadingOverlay } from "src/components";
import { PageLayout } from "src/layout";
import { routes } from "src/routes";
import { useSpotlight } from "src/spotlight";
import { useTranslation } from "src/translations";
import { CustomerStatusEnum } from "src/types";
import { Search } from "../components";
import { SupplyLimitForm } from "./SupplyLimitForm";
import { tableModule } from "./table";
import type { SupplyLimitSelectParams } from "./types";
import { createActionsForSelection } from "./utils";

export const BulkEdit: FC = () => {
    const spotlight = useSpotlight();
    const [customersParamsForBulkEdit, setCustomersParamsForBulkEdit] = useState<SupplyLimitSelectParams | null>(null);
    const detailParams = routes.supplyLimitsBulkEdit.useParams();
    const { data: productPackage, isLoading: isLoadingProductPackage } = useGetProductPackageQuery(detailParams.productPackageId, {
        skip: !detailParams.productPackageId,
    });
    const { t } = useTranslation();

    return (
        <PageLayout
            topSection={<Search onClick={spotlight.openSpotlight} productPackageName={productPackage && productPackage.name} />}
            breadcrumbs={
                productPackage
                    ? [
                          { name: t("supplyLimits.name"), route: routes.supplyLimits.path, icon: Icon.VehicleTruck },
                          {
                              name: productPackage.name,
                              route: routes.supplyLimitsDetail.fillPathParams({ productPackageId: productPackage.id.toString() }),
                          },
                          { name: t("supplyLimits.bulkEditName"), route: routes.supplyLimits.path },
                      ]
                    : [{ name: t("supplyLimits.name"), route: routes.supplyLimits.path, icon: Icon.VehicleTruck }]
            }
        >
            <LoadingOverlay loading={isLoadingProductPackage} />
            {productPackage && (
                <>
                    <tableModule.Container
                        additionalQueryParams={{ productPackageId: productPackage.id }}
                        actionsForSelection={createActionsForSelection(t, setCustomersParamsForBulkEdit)}
                        defaultTableConfig={{ initialFilters: { status: CustomerStatusEnum.Active } }}
                        isSelectable
                    />
                    <Drawer
                        title={t("supplyLimits.drawer.title")}
                        open={!!customersParamsForBulkEdit}
                        onClose={() => setCustomersParamsForBulkEdit(null)}
                    >
                        {customersParamsForBulkEdit && (
                            <SupplyLimitForm
                                closeDrawer={() => setCustomersParamsForBulkEdit(null)}
                                productPackageId={productPackage.id}
                                customersParamsForBulkEdit={customersParamsForBulkEdit}
                            />
                        )}
                    </Drawer>
                </>
            )}
        </PageLayout>
    );
};
