/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 * @interface AcknowledgementErrorDto
 */
export interface AcknowledgementErrorDto {
    /**
     *
     * @type {number}
     * @memberof AcknowledgementErrorDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AcknowledgementErrorDto
     */
    phoenixInterfaceOperationId: number;
    /**
     *
     * @type {number}
     * @memberof AcknowledgementErrorDto
     */
    positionNumber?: number;
    /**
     *
     * @type {string}
     * @memberof AcknowledgementErrorDto
     */
    errorMessage?: string;
    /**
     *
     * @type {string}
     * @memberof AcknowledgementErrorDto
     */
    errorCode?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ActionRequestEnum {
    Approve = "APPROVE",
    Update = "UPDATE",
    Block = "BLOCK",
    Unblock = "UNBLOCK",
    Deactivate = "DEACTIVATE",
    Activate = "ACTIVATE",
}
/**
 *
 * @export
 * @interface AggregationDto
 */
export interface AggregationDto {
    /**
     *
     * @type {number}
     * @memberof AggregationDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AggregationDto
     */
    sapInterfaceId: string;
    /**
     *
     * @type {string}
     * @memberof AggregationDto
     */
    salesOrganisation: string;
    /**
     *
     * @type {string}
     * @memberof AggregationDto
     */
    dateFrom: string;
    /**
     *
     * @type {string}
     * @memberof AggregationDto
     */
    dateTo: string;
    /**
     *
     * @type {string}
     * @memberof AggregationDto
     */
    created: string;
    /**
     *
     * @type {boolean}
     * @memberof AggregationDto
     */
    isCorrective: boolean;
}
/**
 *
 * @export
 * @interface ApproveEmergencyOrderDto
 */
export interface ApproveEmergencyOrderDto {
    /**
     *
     * @type {Array<number>}
     * @memberof ApproveEmergencyOrderDto
     */
    emergencyOrderProductPackageIds: Array<number>;
    /**
     *
     * @type {number}
     * @memberof ApproveEmergencyOrderDto
     */
    approvedAmount?: number;
    /**
     *
     * @type {string}
     * @memberof ApproveEmergencyOrderDto
     */
    azNote?: string;
}
/**
 *
 * @export
 * @interface ApprovePayerDto
 */
export interface ApprovePayerDto {
    /**
     *
     * @type {number}
     * @memberof ApprovePayerDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof ApprovePayerDto
     */
    creditLimit: number;
    /**
     *
     * @type {PaymentDueEnum}
     * @memberof ApprovePayerDto
     */
    paymentDue: PaymentDueEnum;
    /**
     *
     * @type {InvoicingFrequencyEnum}
     * @memberof ApprovePayerDto
     */
    invoicingFrequency: InvoicingFrequencyEnum;
    /**
     *
     * @type {boolean}
     * @memberof ApprovePayerDto
     */
    active: boolean;
}
/**
 *
 * @export
 * @interface ApproveShipToDto
 */
export interface ApproveShipToDto {
    /**
     *
     * @type {number}
     * @memberof ApproveShipToDto
     */
    id: number;
    /**
     *
     * @type {PaymentDueEnum}
     * @memberof ApproveShipToDto
     */
    paymentDue: PaymentDueEnum;
    /**
     *
     * @type {InvoicingFrequencyEnum}
     * @memberof ApproveShipToDto
     */
    invoicingFrequency: InvoicingFrequencyEnum;
    /**
     *
     * @type {boolean}
     * @memberof ApproveShipToDto
     */
    active: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ApproveShipToDto
     */
    propagateSpecialPrices: boolean;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof ApproveShipToDto
     */
    type: CustomerTypeEnum;
    /**
     *
     * @type {number}
     * @memberof ApproveShipToDto
     */
    shipToIdToCopyFrom?: number;
}
/**
 *
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     *
     * @type {number}
     * @memberof AttachmentDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AttachmentDto
     */
    customerId: number;
    /**
     *
     * @type {number}
     * @memberof AttachmentDto
     */
    specialPriceId?: number;
    /**
     *
     * @type {string}
     * @memberof AttachmentDto
     */
    fileName: string;
    /**
     *
     * @type {number}
     * @memberof AttachmentDto
     */
    fileSize: number;
    /**
     *
     * @type {string}
     * @memberof AttachmentDto
     */
    filePath: string;
    /**
     *
     * @type {string}
     * @memberof AttachmentDto
     */
    contentType: string;
    /**
     *
     * @type {string}
     * @memberof AttachmentDto
     */
    attachmentType: AttachmentDtoAttachmentTypeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AttachmentDtoAttachmentTypeEnum {
    Customer = "CUSTOMER",
    SpecialPrice = "SPECIAL_PRICE",
}
/**
 *
 * @export
 * @interface BulkChangeSupplyLimitDto
 */
export interface BulkChangeSupplyLimitDto {
    /**
     *
     * @type {Array<SupplyLimitDto>}
     * @memberof BulkChangeSupplyLimitDto
     */
    currentSupplyLimits: Array<SupplyLimitDto>;
    /**
     *
     * @type {Array<SupplyLimitDto>}
     * @memberof BulkChangeSupplyLimitDto
     */
    availableSupplyLimits: Array<SupplyLimitDto>;
}
/**
 *
 * @export
 * @interface BulkChangeSupplyLimitRequestDto
 */
export interface BulkChangeSupplyLimitRequestDto {
    /**
     *
     * @type {Array<number>}
     * @memberof BulkChangeSupplyLimitRequestDto
     */
    shipToIds?: Array<number>;
    /**
     *
     * @type {ShipToForSupplyLimitFilterDto}
     * @memberof BulkChangeSupplyLimitRequestDto
     */
    filterDto: ShipToForSupplyLimitFilterDto;
    /**
     *
     * @type {number}
     * @memberof BulkChangeSupplyLimitRequestDto
     */
    supplyLimitId: number;
}
/**
 *
 * @export
 * @interface ChangedFieldDto
 */
export interface ChangedFieldDto {
    /**
     *
     * @type {string}
     * @memberof ChangedFieldDto
     */
    column: string;
    /**
     *
     * @type {string}
     * @memberof ChangedFieldDto
     */
    oldValue?: string;
    /**
     *
     * @type {string}
     * @memberof ChangedFieldDto
     */
    newValue?: string;
}
/**
 *
 * @export
 * @interface ClientConfigurationProperties
 */
export interface ClientConfigurationProperties {
    /**
     *
     * @type {string}
     * @memberof ClientConfigurationProperties
     */
    googleRecaptchaKey: string;
    /**
     *
     * @type {number}
     * @memberof ClientConfigurationProperties
     */
    interfaceUploadInterval: number;
}
/**
 *
 * @export
 * @interface CopySupplyLimitsDto
 */
export interface CopySupplyLimitsDto {
    /**
     *
     * @type {number}
     * @memberof CopySupplyLimitsDto
     */
    sourceShipToId: number;
    /**
     *
     * @type {number}
     * @memberof CopySupplyLimitsDto
     */
    targetShipToId: number;
}
/**
 *
 * @export
 * @interface CreateEmergencyOrderDto
 */
export interface CreateEmergencyOrderDto {
    /**
     *
     * @type {number}
     * @memberof CreateEmergencyOrderDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof CreateEmergencyOrderDto
     */
    contactName: string;
    /**
     *
     * @type {string}
     * @memberof CreateEmergencyOrderDto
     */
    contactPhoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof CreateEmergencyOrderDto
     */
    contactEmail: string;
    /**
     *
     * @type {Array<CreateEmergencyOrderProductPackageDto>}
     * @memberof CreateEmergencyOrderDto
     */
    emergencyOrderProductPackages: Array<CreateEmergencyOrderProductPackageDto>;
    /**
     *
     * @type {string}
     * @memberof CreateEmergencyOrderDto
     */
    recaptcha: string;
}
/**
 *
 * @export
 * @interface CreateEmergencyOrderProductPackageDto
 */
export interface CreateEmergencyOrderProductPackageDto {
    /**
     *
     * @type {number}
     * @memberof CreateEmergencyOrderProductPackageDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof CreateEmergencyOrderProductPackageDto
     */
    requestedAmount: number;
    /**
     *
     * @type {string}
     * @memberof CreateEmergencyOrderProductPackageDto
     */
    orderReason: string;
}
/**
 *
 * @export
 * @interface CreateProductBrandDto
 */
export interface CreateProductBrandDto {
    /**
     *
     * @type {string}
     * @memberof CreateProductBrandDto
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateProductBrandDto
     */
    active: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CreateProductBrandDto
     */
    archived: boolean;
}
/**
 *
 * @export
 * @interface CreateProductPackageDto
 */
export interface CreateProductPackageDto {
    /**
     *
     * @type {number}
     * @memberof CreateProductPackageDto
     */
    productBrandId: number;
    /**
     *
     * @type {string}
     * @memberof CreateProductPackageDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CreateProductPackageDto
     */
    sapCode: string;
    /**
     *
     * @type {string}
     * @memberof CreateProductPackageDto
     */
    agentCode: string;
    /**
     *
     * @type {string}
     * @memberof CreateProductPackageDto
     */
    eanCode?: string;
    /**
     *
     * @type {string}
     * @memberof CreateProductPackageDto
     */
    suklCode: string;
    /**
     *
     * @type {number}
     * @memberof CreateProductPackageDto
     */
    margin: number;
    /**
     *
     * @type {boolean}
     * @memberof CreateProductPackageDto
     */
    active: boolean;
    /**
     *
     * @type {CreateProductPriceAlongWithPackageDto}
     * @memberof CreateProductPackageDto
     */
    price?: CreateProductPriceAlongWithPackageDto;
}
/**
 *
 * @export
 * @interface CreateProductPriceAlongWithPackageDto
 */
export interface CreateProductPriceAlongWithPackageDto {
    /**
     *
     * @type {number}
     * @memberof CreateProductPriceAlongWithPackageDto
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof CreateProductPriceAlongWithPackageDto
     */
    validFrom: string;
}
/**
 *
 * @export
 * @interface CreateProductPriceDto
 */
export interface CreateProductPriceDto {
    /**
     *
     * @type {number}
     * @memberof CreateProductPriceDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof CreateProductPriceDto
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof CreateProductPriceDto
     */
    validFrom: string;
    /**
     *
     * @type {string}
     * @memberof CreateProductPriceDto
     */
    validTo?: string;
}
/**
 *
 * @export
 * @interface CreateSpecialPriceDto
 */
export interface CreateSpecialPriceDto {
    /**
     *
     * @type {number}
     * @memberof CreateSpecialPriceDto
     */
    customerId: number;
    /**
     *
     * @type {number}
     * @memberof CreateSpecialPriceDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof CreateSpecialPriceDto
     */
    specialPriceInclMargin: number;
    /**
     *
     * @type {string}
     * @memberof CreateSpecialPriceDto
     */
    validFrom: string;
    /**
     *
     * @type {string}
     * @memberof CreateSpecialPriceDto
     */
    validTo?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSpecialPriceDto
     */
    contractId: string;
    /**
     *
     * @type {string}
     * @memberof CreateSpecialPriceDto
     */
    contractFrom?: string;
    /**
     *
     * @type {string}
     * @memberof CreateSpecialPriceDto
     */
    contractTo?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof CreateSpecialPriceDto
     */
    applyToShipTos?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof CreateSpecialPriceDto
     */
    azNote?: string;
}
/**
 *
 * @export
 * @interface CreateSupplyLimitDto
 */
export interface CreateSupplyLimitDto {
    /**
     *
     * @type {number}
     * @memberof CreateSupplyLimitDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof CreateSupplyLimitDto
     */
    tierNumber: number;
    /**
     *
     * @type {number}
     * @memberof CreateSupplyLimitDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof CreateSupplyLimitDto
     */
    dailyLimit: number;
}
/**
 *
 * @export
 * @interface CustomerActionRequestDto
 */
export interface CustomerActionRequestDto {
    /**
     *
     * @type {Array<number>}
     * @memberof CustomerActionRequestDto
     */
    customerIds?: Array<number>;
    /**
     *
     * @type {CustomerFilterDto}
     * @memberof CustomerActionRequestDto
     */
    filterDto?: CustomerFilterDto;
}
/**
 *
 * @export
 * @interface CustomerAttachmentFilterDto
 */
export interface CustomerAttachmentFilterDto {
    /**
     *
     * @type {string}
     * @memberof CustomerAttachmentFilterDto
     */
    fileName?: string;
}
/**
 *
 * @export
 * @interface CustomerBranchDto
 */
export interface CustomerBranchDto {
    /**
     *
     * @type {number}
     * @memberof CustomerBranchDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof CustomerBranchDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof CustomerBranchDto
     */
    code: number;
    /**
     *
     * @type {boolean}
     * @memberof CustomerBranchDto
     */
    isHospital: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CustomerCategoryEnum {
    Payer = "PAYER",
    ShipTo = "SHIP_TO",
}
/**
 *
 * @export
 * @interface CustomerChainWithEmergencyOrdersDto
 */
export interface CustomerChainWithEmergencyOrdersDto {
    /**
     *
     * @type {string}
     * @memberof CustomerChainWithEmergencyOrdersDto
     */
    chain: string;
    /**
     *
     * @type {Array<EmergencyOrderProductPackageListDto>}
     * @memberof CustomerChainWithEmergencyOrdersDto
     */
    emergencyOrders: Array<EmergencyOrderProductPackageListDto>;
}
/**
 *
 * @export
 * @interface CustomerChainWithSupplyLimitSuggestionsDto
 */
export interface CustomerChainWithSupplyLimitSuggestionsDto {
    /**
     *
     * @type {string}
     * @memberof CustomerChainWithSupplyLimitSuggestionsDto
     */
    chain: string;
    /**
     *
     * @type {Array<SupplyLimitSuggestionDto>}
     * @memberof CustomerChainWithSupplyLimitSuggestionsDto
     */
    supplyLimitSuggestions: Array<SupplyLimitSuggestionDto>;
}
/**
 *
 * @export
 * @interface CustomerCountPerActionRequestDto
 */
export interface CustomerCountPerActionRequestDto {
    /**
     *
     * @type {ActionRequestEnum}
     * @memberof CustomerCountPerActionRequestDto
     */
    actionRequest: ActionRequestEnum;
    /**
     *
     * @type {number}
     * @memberof CustomerCountPerActionRequestDto
     */
    count: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CustomerCredibilityEnum {
    Bad = "BAD",
    Suspicious = "SUSPICIOUS",
    Good = "GOOD",
}
/**
 *
 * @export
 * @interface CustomerDebtListDto
 */
export interface CustomerDebtListDto {
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof CustomerDebtListDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    acn: number;
    /**
     *
     * @type {string}
     * @memberof CustomerDebtListDto
     */
    tin: string;
    /**
     *
     * @type {Array<CustomerBranchDto>}
     * @memberof CustomerDebtListDto
     */
    branches: Array<CustomerBranchDto>;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    totalGross?: number;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    day0814?: number;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    day1521?: number;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    day2228?: number;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    day2960?: number;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    day6190?: number;
    /**
     *
     * @type {number}
     * @memberof CustomerDebtListDto
     */
    day90?: number;
}
/**
 *
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     *
     * @type {number}
     * @memberof CustomerDto
     */
    id: number;
    /**
     *
     * @type {CustomerCategoryEnum}
     * @memberof CustomerDto
     */
    category: CustomerCategoryEnum;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof CustomerDto
     */
    acn: number;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    tin: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    vatNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    zip: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    phone?: string;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof CustomerDto
     */
    status: CustomerStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof CustomerDto
     */
    credibility?: CustomerCredibilityEnum;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    tradingStartDate?: string;
    /**
     *
     * @type {number}
     * @memberof CustomerDto
     */
    creditLimit?: number;
    /**
     *
     * @type {PaymentDueEnum}
     * @memberof CustomerDto
     */
    paymentDue?: PaymentDueEnum;
    /**
     *
     * @type {InvoicingFrequencyEnum}
     * @memberof CustomerDto
     */
    invoicingFrequency?: InvoicingFrequencyEnum;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    chain?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    note?: string;
    /**
     *
     * @type {CustomerDto}
     * @memberof CustomerDto
     */
    payer?: CustomerDto;
    /**
     *
     * @type {Array<ActionRequestEnum>}
     * @memberof CustomerDto
     */
    actionRequests?: Array<ActionRequestEnum>;
    /**
     *
     * @type {string}
     * @memberof CustomerDto
     */
    leadingPersonName?: string;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof CustomerDto
     */
    type?: CustomerTypeEnum;
    /**
     *
     * @type {CustomerBranchDto}
     * @memberof CustomerDto
     */
    branch?: CustomerBranchDto;
    /**
     *
     * @type {Array<CustomerDto>}
     * @memberof CustomerDto
     */
    shipToList?: Array<CustomerDto>;
}
/**
 *
 * @export
 * @interface CustomerFilterDto
 */
export interface CustomerFilterDto {
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    acn?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    tin?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    street?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    zip?: string;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof CustomerFilterDto
     */
    type?: CustomerTypeEnum;
    /**
     *
     * @type {ActionRequestEnum}
     * @memberof CustomerFilterDto
     */
    actionRequest?: ActionRequestEnum;
    /**
     *
     * @type {Array<CustomerCategoryEnum>}
     * @memberof CustomerFilterDto
     */
    categories?: Array<CustomerCategoryEnum>;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    vatNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    payerName?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerFilterDto
     */
    payerAcn?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof CustomerFilterDto
     */
    branchIds?: Array<number>;
    /**
     *
     * @type {boolean}
     * @memberof CustomerFilterDto
     */
    isHospital?: boolean;
}
/**
 *
 * @export
 * @interface CustomerListDto
 */
export interface CustomerListDto {
    /**
     *
     * @type {number}
     * @memberof CustomerListDto
     */
    id: number;
    /**
     *
     * @type {CustomerCategoryEnum}
     * @memberof CustomerListDto
     */
    category: CustomerCategoryEnum;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof CustomerListDto
     */
    acn: number;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    tin: string;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    vatNumber?: string;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    zip: string;
    /**
     *
     * @type {string}
     * @memberof CustomerListDto
     */
    payerName?: string;
    /**
     *
     * @type {number}
     * @memberof CustomerListDto
     */
    payerAcn?: number;
    /**
     *
     * @type {boolean}
     * @memberof CustomerListDto
     */
    payerApproved?: boolean;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof CustomerListDto
     */
    type?: CustomerTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CustomerStatusEnum {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    Blocked = "BLOCKED",
    New = "NEW",
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CustomerTypeEnum {
    Hospital = "HOSPITAL",
    StreetPharmacy = "STREET_PHARMACY",
    PharmacyChain = "PHARMACY_CHAIN",
    HospitalChain = "HOSPITAL_CHAIN",
    HealthCenter = "HEALTH_CENTER",
    NotSpecified = "NOT_SPECIFIED",
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DataModificationLogActionEnum {
    I = "I",
    U = "U",
    D = "D",
}
/**
 *
 * @export
 * @interface DataModificationLogDto
 */
export interface DataModificationLogDto {
    /**
     *
     * @type {number}
     * @memberof DataModificationLogDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof DataModificationLogDto
     */
    timestamp: string;
    /**
     *
     * @type {string}
     * @memberof DataModificationLogDto
     */
    userName?: string;
    /**
     *
     * @type {DataModificationLogActionEnum}
     * @memberof DataModificationLogDto
     */
    action: DataModificationLogActionEnum;
    /**
     *
     * @type {DataModificationLogTableEnum}
     * @memberof DataModificationLogDto
     */
    table: DataModificationLogTableEnum;
    /**
     *
     * @type {Array<ChangedFieldDto>}
     * @memberof DataModificationLogDto
     */
    changedFields: Array<ChangedFieldDto>;
}
/**
 *
 * @export
 * @interface DataModificationLogFilterDto
 */
export interface DataModificationLogFilterDto {
    /**
     *
     * @type {number}
     * @memberof DataModificationLogFilterDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof DataModificationLogFilterDto
     */
    date?: string;
    /**
     *
     * @type {string}
     * @memberof DataModificationLogFilterDto
     */
    userName?: string;
    /**
     *
     * @type {Array<DataModificationLogActionEnum>}
     * @memberof DataModificationLogFilterDto
     */
    actions?: Array<DataModificationLogActionEnum>;
    /**
     *
     * @type {Array<DataModificationLogTableEnum>}
     * @memberof DataModificationLogFilterDto
     */
    tables?: Array<DataModificationLogTableEnum>;
    /**
     *
     * @type {string}
     * @memberof DataModificationLogFilterDto
     */
    column?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DataModificationLogTableEnum {
    CustomerTable = "CUSTOMER_TABLE",
    CustomerProductPackageTable = "CUSTOMER_PRODUCT_PACKAGE_TABLE",
    SpecialPriceTable = "SPECIAL_PRICE_TABLE",
    CustomerSupplyLimitTable = "CUSTOMER_SUPPLY_LIMIT_TABLE",
    EmergencyOrderAdjustmentTable = "EMERGENCY_ORDER_ADJUSTMENT_TABLE",
    SupplyLimitSuggestionTable = "SUPPLY_LIMIT_SUGGESTION_TABLE",
}
/**
 *
 * @export
 * @interface DiffObject
 */
export interface DiffObject {
    /**
     *
     * @type {object}
     * @memberof DiffObject
     */
    oldValue?: object;
    /**
     *
     * @type {object}
     * @memberof DiffObject
     */
    newValue?: object;
}
/**
 *
 * @export
 * @interface EmergencyOrderFilterDto
 */
export interface EmergencyOrderFilterDto {
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderFilterDto
     */
    customerName?: string;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderFilterDto
     */
    customerAcn?: string;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderFilterDto
     */
    productPackageName?: string;
    /**
     *
     * @type {EmergencyOrderProductPackageStatusEnum}
     * @memberof EmergencyOrderFilterDto
     */
    status?: EmergencyOrderProductPackageStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof EmergencyOrderFilterDto
     */
    customerCredibility?: CustomerCredibilityEnum;
}
/**
 *
 * @export
 * @interface EmergencyOrderProductPackageDto
 */
export interface EmergencyOrderProductPackageDto {
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    tierNumber: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    requestedAmount: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    dailyLimit: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    dailyVolumeAdjustment: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    monthlyVolumeAdjustment: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    todayOrderVolume: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    currentMonthOrderVolume: number;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderProductPackageDto
     */
    orderReason: string;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    eslMonthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    approvedThisMonth: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageDto
     */
    processedThisMonth: number;
}
/**
 *
 * @export
 * @interface EmergencyOrderProductPackageListDto
 */
export interface EmergencyOrderProductPackageListDto {
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderProductPackageListDto
     */
    customerName: string;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderProductPackageListDto
     */
    customerAcn: string;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderProductPackageListDto
     */
    requestedOn: string;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    productPackageId: number;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderProductPackageListDto
     */
    productPackageName: string;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    tierNumber: number;
    /**
     *
     * @type {boolean}
     * @memberof EmergencyOrderProductPackageListDto
     */
    isDefaultTier: boolean;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    dailyLimit: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    dailyVolumeAdjustment: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    monthlyVolumeAdjustment: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    requestedAmount: number;
    /**
     *
     * @type {string}
     * @memberof EmergencyOrderProductPackageListDto
     */
    orderReason: string;
    /**
     *
     * @type {EmergencyOrderProductPackageStatusEnum}
     * @memberof EmergencyOrderProductPackageListDto
     */
    status: EmergencyOrderProductPackageStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof EmergencyOrderProductPackageListDto
     */
    customerCredibility: CustomerCredibilityEnum;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    monthlyVolumeAdjustmentBeforeProcess?: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    approvedThisMonth?: number;
    /**
     *
     * @type {number}
     * @memberof EmergencyOrderProductPackageListDto
     */
    processedThisMonth?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EmergencyOrderProductPackageStatusEnum {
    Waiting = "WAITING",
    Approved = "APPROVED",
    Refused = "REFUSED",
}
/**
 *
 * @export
 * @interface GenerateDto
 */
export interface GenerateDto {
    /**
     *
     * @type {string}
     * @memberof GenerateDto
     */
    from: string;
    /**
     *
     * @type {string}
     * @memberof GenerateDto
     */
    to: string;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     *
     * @type {Blob}
     * @memberof InlineObject
     */
    file: Blob;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum InvoicingFrequencyEnum {
    Immediately = "IMMEDIATELY",
    Daily = "DAILY",
    Weekly = "WEEKLY",
    Biweekly = "BIWEEKLY",
    Monthly = "MONTHLY",
}
/**
 *
 * @export
 * @interface LastPhoenixInterfaceDto
 */
export interface LastPhoenixInterfaceDto {
    /**
     *
     * @type {PhoenixInterfaceOperationDto}
     * @memberof LastPhoenixInterfaceDto
     */
    lastInterface?: PhoenixInterfaceOperationDto;
    /**
     *
     * @type {PhoenixInterfaceOperationDto}
     * @memberof LastPhoenixInterfaceDto
     */
    lastFailedInterface?: PhoenixInterfaceOperationDto;
    /**
     *
     * @type {PhoenixInterfaceOperationDto}
     * @memberof LastPhoenixInterfaceDto
     */
    lastInProgressInterface?: PhoenixInterfaceOperationDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MarginSourceEnum {
    SpecialPriceCustomer = "SPECIAL_PRICE_CUSTOMER",
    SpecialPriceInheritedFromPayer = "SPECIAL_PRICE_INHERITED_FROM_PAYER",
    Customer = "CUSTOMER",
    InheritedFromPayer = "INHERITED_FROM_PAYER",
    ProductPackage = "PRODUCT_PACKAGE",
}
/**
 *
 * @export
 * @interface PaginationRequestDto
 */
export interface PaginationRequestDto {
    /**
     * Page order
     * @type {number}
     * @memberof PaginationRequestDto
     */
    pageNumber?: number;
    /**
     * Count of elements on one page
     * @type {number}
     * @memberof PaginationRequestDto
     */
    pageSize?: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoAggregationDto
 */
export interface PaginationResponseDtoAggregationDto {
    /**
     *
     * @type {Array<AggregationDto>}
     * @memberof PaginationResponseDtoAggregationDto
     */
    content: Array<AggregationDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoAggregationDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoAggregationDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoAggregationDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoAttachmentDto
 */
export interface PaginationResponseDtoAttachmentDto {
    /**
     *
     * @type {Array<AttachmentDto>}
     * @memberof PaginationResponseDtoAttachmentDto
     */
    content: Array<AttachmentDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoAttachmentDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoAttachmentDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoAttachmentDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoCustomerChainWithEmergencyOrdersDto
 */
export interface PaginationResponseDtoCustomerChainWithEmergencyOrdersDto {
    /**
     *
     * @type {Array<CustomerChainWithEmergencyOrdersDto>}
     * @memberof PaginationResponseDtoCustomerChainWithEmergencyOrdersDto
     */
    content: Array<CustomerChainWithEmergencyOrdersDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerChainWithEmergencyOrdersDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerChainWithEmergencyOrdersDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerChainWithEmergencyOrdersDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto
 */
export interface PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto {
    /**
     *
     * @type {Array<CustomerChainWithSupplyLimitSuggestionsDto>}
     * @memberof PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto
     */
    content: Array<CustomerChainWithSupplyLimitSuggestionsDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerChainWithSupplyLimitSuggestionsDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoCustomerDebtListDto
 */
export interface PaginationResponseDtoCustomerDebtListDto {
    /**
     *
     * @type {Array<CustomerDebtListDto>}
     * @memberof PaginationResponseDtoCustomerDebtListDto
     */
    content: Array<CustomerDebtListDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerDebtListDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerDebtListDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerDebtListDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoCustomerListDto
 */
export interface PaginationResponseDtoCustomerListDto {
    /**
     *
     * @type {Array<CustomerListDto>}
     * @memberof PaginationResponseDtoCustomerListDto
     */
    content: Array<CustomerListDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerListDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerListDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoCustomerListDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoDataModificationLogDto
 */
export interface PaginationResponseDtoDataModificationLogDto {
    /**
     *
     * @type {Array<DataModificationLogDto>}
     * @memberof PaginationResponseDtoDataModificationLogDto
     */
    content: Array<DataModificationLogDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoDataModificationLogDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoDataModificationLogDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoDataModificationLogDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoEmergencyOrderProductPackageListDto
 */
export interface PaginationResponseDtoEmergencyOrderProductPackageListDto {
    /**
     *
     * @type {Array<EmergencyOrderProductPackageListDto>}
     * @memberof PaginationResponseDtoEmergencyOrderProductPackageListDto
     */
    content: Array<EmergencyOrderProductPackageListDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoEmergencyOrderProductPackageListDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoEmergencyOrderProductPackageListDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoEmergencyOrderProductPackageListDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoPayerListDto
 */
export interface PaginationResponseDtoPayerListDto {
    /**
     *
     * @type {Array<PayerListDto>}
     * @memberof PaginationResponseDtoPayerListDto
     */
    content: Array<PayerListDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoPayerListDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoPayerListDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoPayerListDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoPhoenixInterfaceOperationDto
 */
export interface PaginationResponseDtoPhoenixInterfaceOperationDto {
    /**
     *
     * @type {Array<PhoenixInterfaceOperationDto>}
     * @memberof PaginationResponseDtoPhoenixInterfaceOperationDto
     */
    content: Array<PhoenixInterfaceOperationDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoPhoenixInterfaceOperationDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoPhoenixInterfaceOperationDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoPhoenixInterfaceOperationDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoProductBrandDto
 */
export interface PaginationResponseDtoProductBrandDto {
    /**
     *
     * @type {Array<ProductBrandDto>}
     * @memberof PaginationResponseDtoProductBrandDto
     */
    content: Array<ProductBrandDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoProductBrandWithMarginsDto
 */
export interface PaginationResponseDtoProductBrandWithMarginsDto {
    /**
     *
     * @type {Array<ProductBrandWithMarginsDto>}
     * @memberof PaginationResponseDtoProductBrandWithMarginsDto
     */
    content: Array<ProductBrandWithMarginsDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithMarginsDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithMarginsDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithMarginsDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoProductBrandWithSpecialPricesDto
 */
export interface PaginationResponseDtoProductBrandWithSpecialPricesDto {
    /**
     *
     * @type {Array<ProductBrandWithSpecialPricesDto>}
     * @memberof PaginationResponseDtoProductBrandWithSpecialPricesDto
     */
    content: Array<ProductBrandWithSpecialPricesDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithSpecialPricesDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithSpecialPricesDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithSpecialPricesDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoProductBrandWithSupplyLimitsDto
 */
export interface PaginationResponseDtoProductBrandWithSupplyLimitsDto {
    /**
     *
     * @type {Array<ProductBrandWithSupplyLimitsDto>}
     * @memberof PaginationResponseDtoProductBrandWithSupplyLimitsDto
     */
    content: Array<ProductBrandWithSupplyLimitsDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithSupplyLimitsDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithSupplyLimitsDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductBrandWithSupplyLimitsDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoProductPackageWithPricesDto
 */
export interface PaginationResponseDtoProductPackageWithPricesDto {
    /**
     *
     * @type {Array<ProductPackageWithPricesDto>}
     * @memberof PaginationResponseDtoProductPackageWithPricesDto
     */
    content: Array<ProductPackageWithPricesDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductPackageWithPricesDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductPackageWithPricesDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoProductPackageWithPricesDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoShipToForSupplyLimitDto
 */
export interface PaginationResponseDtoShipToForSupplyLimitDto {
    /**
     *
     * @type {Array<ShipToForSupplyLimitDto>}
     * @memberof PaginationResponseDtoShipToForSupplyLimitDto
     */
    content: Array<ShipToForSupplyLimitDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoShipToForSupplyLimitDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoShipToForSupplyLimitDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoShipToForSupplyLimitDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoShipToListDto
 */
export interface PaginationResponseDtoShipToListDto {
    /**
     *
     * @type {Array<ShipToListDto>}
     * @memberof PaginationResponseDtoShipToListDto
     */
    content: Array<ShipToListDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoShipToListDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoShipToListDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoShipToListDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PaginationResponseDtoSupplyLimitSuggestionDto
 */
export interface PaginationResponseDtoSupplyLimitSuggestionDto {
    /**
     *
     * @type {Array<SupplyLimitSuggestionDto>}
     * @memberof PaginationResponseDtoSupplyLimitSuggestionDto
     */
    content: Array<SupplyLimitSuggestionDto>;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoSupplyLimitSuggestionDto
     */
    pageNumber: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoSupplyLimitSuggestionDto
     */
    pageSize: number;
    /**
     *
     * @type {number}
     * @memberof PaginationResponseDtoSupplyLimitSuggestionDto
     */
    totalElements: number;
}
/**
 *
 * @export
 * @interface PayerFilterDto
 */
export interface PayerFilterDto {
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    acn?: string;
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    tin?: string;
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    street?: string;
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    zip?: string;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof PayerFilterDto
     */
    type?: CustomerTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PayerFilterDto
     */
    vatNumber?: string;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof PayerFilterDto
     */
    status?: CustomerStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PayerFilterDto
     */
    branchId?: number;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof PayerFilterDto
     */
    credibility?: CustomerCredibilityEnum;
}
/**
 *
 * @export
 * @interface PayerListDto
 */
export interface PayerListDto {
    /**
     *
     * @type {number}
     * @memberof PayerListDto
     */
    id: number;
    /**
     *
     * @type {CustomerCategoryEnum}
     * @memberof PayerListDto
     */
    category: CustomerCategoryEnum;
    /**
     *
     * @type {string}
     * @memberof PayerListDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof PayerListDto
     */
    acn: number;
    /**
     *
     * @type {string}
     * @memberof PayerListDto
     */
    tin: string;
    /**
     *
     * @type {string}
     * @memberof PayerListDto
     */
    vatNumber?: string;
    /**
     *
     * @type {string}
     * @memberof PayerListDto
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof PayerListDto
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof PayerListDto
     */
    zip: string;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof PayerListDto
     */
    status: CustomerStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof PayerListDto
     */
    credibility?: CustomerCredibilityEnum;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof PayerListDto
     */
    type?: CustomerTypeEnum;
    /**
     *
     * @type {CustomerBranchDto}
     * @memberof PayerListDto
     */
    branch?: CustomerBranchDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentDueEnum {
    _0 = "DAYS_0",
    _7 = "DAYS_7",
    _14 = "DAYS_14",
    _21 = "DAYS_21",
    _28 = "DAYS_28",
    _30 = "DAYS_30",
    _40 = "DAYS_40",
    _45 = "DAYS_45",
    _60 = "DAYS_60",
    _75 = "DAYS_75",
    _90 = "DAYS_90",
    _120 = "DAYS_120",
    _180 = "DAYS_180",
}
/**
 *
 * @export
 * @interface PhoenixInterfaceOperationDto
 */
export interface PhoenixInterfaceOperationDto {
    /**
     *
     * @type {number}
     * @memberof PhoenixInterfaceOperationDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationDto
     */
    operationTimestamp: string;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationDto
     */
    interfaceType: PhoenixInterfaceOperationDtoInterfaceTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationDto
     */
    filename: string;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationDto
     */
    status: PhoenixInterfaceOperationDtoStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PhoenixInterfaceOperationDto
     */
    sequenceNumber?: number;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationDto
     */
    errorMessage?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PhoenixInterfaceOperationDtoInterfaceTypeEnum {
    MasterData = "CUSTOMER_MASTER_DATA",
    Invoices = "CUSTOMER_INVOICES",
    RejectedOrders = "CUSTOMER_REJECTED_ORDERS",
    DeliveryNotes = "CUSTOMER_DELIVERY_NOTES",
    StockBalance = "CUSTOMER_STOCK_BALANCE",
    InventoryMovements = "CUSTOMER_INVENTORY_MOVEMENTS",
    AccountReceivables = "CUSTOMER_ACCOUNT_RECEIVABLES",
    TradingTerms = "CUSTOMER_TRADING_TERMS",
    Quotas = "CUSTOMER_QUOTAS",
}
/**
 * @export
 * @enum {string}
 */
export enum PhoenixInterfaceOperationDtoStatusEnum {
    InProgress = "IN_PROGRESS",
    Success = "SUCCESS",
    Error = "ERROR",
}
/**
 *
 * @export
 * @interface PhoenixInterfaceOperationFilterDto
 */
export interface PhoenixInterfaceOperationFilterDto {
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationFilterDto
     */
    interfaceOperationType: PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationFilterDto
     */
    interfaceType?: PhoenixInterfaceOperationFilterDtoInterfaceTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationFilterDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationFilterDto
     */
    status?: PhoenixInterfaceOperationFilterDtoStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PhoenixInterfaceOperationFilterDto
     */
    rejectReason?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PhoenixInterfaceOperationFilterDtoInterfaceOperationTypeEnum {
    Incoming = "INCOMING",
    Outgoing = "OUTGOING",
}
/**
 * @export
 * @enum {string}
 */
export enum PhoenixInterfaceOperationFilterDtoInterfaceTypeEnum {
    MasterData = "CUSTOMER_MASTER_DATA",
    Invoices = "CUSTOMER_INVOICES",
    RejectedOrders = "CUSTOMER_REJECTED_ORDERS",
    DeliveryNotes = "CUSTOMER_DELIVERY_NOTES",
    StockBalance = "CUSTOMER_STOCK_BALANCE",
    InventoryMovements = "CUSTOMER_INVENTORY_MOVEMENTS",
    AccountReceivables = "CUSTOMER_ACCOUNT_RECEIVABLES",
    TradingTerms = "CUSTOMER_TRADING_TERMS",
    Quotas = "CUSTOMER_QUOTAS",
}
/**
 * @export
 * @enum {string}
 */
export enum PhoenixInterfaceOperationFilterDtoStatusEnum {
    InProgress = "IN_PROGRESS",
    Success = "SUCCESS",
    Error = "ERROR",
}
/**
 *
 * @export
 * @interface ProductBrandDto
 */
export interface ProductBrandDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandDto
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductBrandDto
     */
    active: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductBrandDto
     */
    archived: boolean;
    /**
     *
     * @type {Array<ProductPackageDto>}
     * @memberof ProductBrandDto
     */
    packages?: Array<ProductPackageDto>;
}
/**
 *
 * @export
 * @interface ProductBrandWithMarginsDto
 */
export interface ProductBrandWithMarginsDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithMarginsDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithMarginsDto
     */
    name: string;
    /**
     *
     * @type {Array<ProductPackageWithMarginDto>}
     * @memberof ProductBrandWithMarginsDto
     */
    margins: Array<ProductPackageWithMarginDto>;
}
/**
 *
 * @export
 * @interface ProductBrandWithMarginsFilterDto
 */
export interface ProductBrandWithMarginsFilterDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithMarginsFilterDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithMarginsFilterDto
     */
    name?: string;
    /**
     *
     * @type {MarginSourceEnum}
     * @memberof ProductBrandWithMarginsFilterDto
     */
    marginSource?: MarginSourceEnum;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithMarginsFilterDto
     */
    marginValidityTimestamp: string;
}
/**
 *
 * @export
 * @interface ProductBrandWithSpecialPricesDto
 */
export interface ProductBrandWithSpecialPricesDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSpecialPricesDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithSpecialPricesDto
     */
    name: string;
    /**
     *
     * @type {Array<SpecialPriceDto>}
     * @memberof ProductBrandWithSpecialPricesDto
     */
    specialPrices: Array<SpecialPriceDto>;
}
/**
 *
 * @export
 * @interface ProductBrandWithSpecialPricesFilterDto
 */
export interface ProductBrandWithSpecialPricesFilterDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSpecialPricesFilterDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithSpecialPricesFilterDto
     */
    name?: string;
}
/**
 *
 * @export
 * @interface ProductBrandWithSupplyLimitsAndMarginsFilterDto
 */
export interface ProductBrandWithSupplyLimitsAndMarginsFilterDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    tierNumber?: number;
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    dailyLimit?: number;
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    monthlyLimit?: number;
    /**
     *
     * @type {MarginSourceEnum}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    marginSource?: MarginSourceEnum;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithSupplyLimitsAndMarginsFilterDto
     */
    marginValidityTimestamp: string;
}
/**
 *
 * @export
 * @interface ProductBrandWithSupplyLimitsDto
 */
export interface ProductBrandWithSupplyLimitsDto {
    /**
     *
     * @type {number}
     * @memberof ProductBrandWithSupplyLimitsDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductBrandWithSupplyLimitsDto
     */
    name: string;
    /**
     *
     * @type {Array<SupplyLimitWithPackageAndMarginDto>}
     * @memberof ProductBrandWithSupplyLimitsDto
     */
    supplyLimits: Array<SupplyLimitWithPackageAndMarginDto>;
}
/**
 *
 * @export
 * @interface ProductPackageDto
 */
export interface ProductPackageDto {
    /**
     *
     * @type {number}
     * @memberof ProductPackageDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof ProductPackageDto
     */
    productBrandId: number;
    /**
     *
     * @type {string}
     * @memberof ProductPackageDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageDto
     */
    sapCode: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageDto
     */
    agentCode: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageDto
     */
    eanCode?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageDto
     */
    suklCode: string;
    /**
     *
     * @type {number}
     * @memberof ProductPackageDto
     */
    margin: number;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageDto
     */
    active: boolean;
    /**
     *
     * @type {ProductPriceDto}
     * @memberof ProductPackageDto
     */
    currentPrice?: ProductPriceDto;
}
/**
 *
 * @export
 * @interface ProductPackageFilterDto
 */
export interface ProductPackageFilterDto {
    /**
     *
     * @type {string}
     * @memberof ProductPackageFilterDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageFilterDto
     */
    sapCode?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageFilterDto
     */
    agentCode?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageFilterDto
     */
    eanCode?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageFilterDto
     */
    suklCode?: string;
    /**
     *
     * @type {number}
     * @memberof ProductPackageFilterDto
     */
    margin?: number;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageFilterDto
     */
    active?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageFilterDto
     */
    archived?: boolean;
}
/**
 *
 * @export
 * @interface ProductPackagePublicFormDto
 */
export interface ProductPackagePublicFormDto {
    /**
     *
     * @type {number}
     * @memberof ProductPackagePublicFormDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductPackagePublicFormDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackagePublicFormDto
     */
    suklCode: string;
}
/**
 *
 * @export
 * @interface ProductPackageWithMarginDto
 */
export interface ProductPackageWithMarginDto {
    /**
     *
     * @type {number}
     * @memberof ProductPackageWithMarginDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductPackageWithMarginDto
     */
    productPackageName: string;
    /**
     *
     * @type {number}
     * @memberof ProductPackageWithMarginDto
     */
    margin: number;
    /**
     *
     * @type {MarginSourceEnum}
     * @memberof ProductPackageWithMarginDto
     */
    marginSource: MarginSourceEnum;
}
/**
 *
 * @export
 * @interface ProductPackageWithPricesDto
 */
export interface ProductPackageWithPricesDto {
    /**
     *
     * @type {number}
     * @memberof ProductPackageWithPricesDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProductPackageWithPricesDto
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageWithPricesDto
     */
    active: boolean;
    /**
     *
     * @type {ProductPriceDto}
     * @memberof ProductPackageWithPricesDto
     */
    currentPrice?: ProductPriceDto;
    /**
     *
     * @type {Array<ProductPriceDto>}
     * @memberof ProductPackageWithPricesDto
     */
    prices: Array<ProductPriceDto>;
}
/**
 *
 * @export
 * @interface ProductPriceDto
 */
export interface ProductPriceDto {
    /**
     *
     * @type {number}
     * @memberof ProductPriceDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof ProductPriceDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof ProductPriceDto
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof ProductPriceDto
     */
    validFrom: string;
    /**
     *
     * @type {string}
     * @memberof ProductPriceDto
     */
    validTo: string;
}
/**
 *
 * @export
 * @interface ProductPriceFilterDto
 */
export interface ProductPriceFilterDto {
    /**
     *
     * @type {string}
     * @memberof ProductPriceFilterDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPriceFilterDto
     */
    validFrom?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPriceFilterDto
     */
    validTo?: string;
}
/**
 *
 * @export
 * @interface RefuseEmergencyOrderDto
 */
export interface RefuseEmergencyOrderDto {
    /**
     *
     * @type {Array<number>}
     * @memberof RefuseEmergencyOrderDto
     */
    emergencyOrderProductPackageIds: Array<number>;
    /**
     *
     * @type {string}
     * @memberof RefuseEmergencyOrderDto
     */
    refusalReason: string;
    /**
     *
     * @type {string}
     * @memberof RefuseEmergencyOrderDto
     */
    azNote?: string;
}
/**
 *
 * @export
 * @interface ResetMarginsForPayerDto
 */
export interface ResetMarginsForPayerDto {
    /**
     *
     * @type {Array<number>}
     * @memberof ResetMarginsForPayerDto
     */
    productPackageIds?: Array<number>;
    /**
     *
     * @type {ProductBrandWithMarginsFilterDto}
     * @memberof ResetMarginsForPayerDto
     */
    filterDto: ProductBrandWithMarginsFilterDto;
}
/**
 *
 * @export
 * @interface SearchDto
 */
export interface SearchDto {
    /**
     *
     * @type {number}
     * @memberof SearchDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof SearchDto
     */
    name: string;
    /**
     *
     * @type {SearchResultTypeEnum}
     * @memberof SearchDto
     */
    type: SearchResultTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SearchResultTypeEnum {
    ProductPackage = "PRODUCT_PACKAGE",
    Customer = "CUSTOMER",
}
/**
 *
 * @export
 * @interface ShipToFilterDto
 */
export interface ShipToFilterDto {
    /**
     *
     * @type {string}
     * @memberof ShipToFilterDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToFilterDto
     */
    acn?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToFilterDto
     */
    street?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToFilterDto
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToFilterDto
     */
    zip?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToFilterDto
     */
    tin?: string;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof ShipToFilterDto
     */
    type?: CustomerTypeEnum;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof ShipToFilterDto
     */
    status?: CustomerStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof ShipToFilterDto
     */
    credibility?: CustomerCredibilityEnum;
}
/**
 *
 * @export
 * @interface ShipToForCopySupplyLimitsDto
 */
export interface ShipToForCopySupplyLimitsDto {
    /**
     *
     * @type {number}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    acn: number;
    /**
     *
     * @type {string}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    zip: string;
    /**
     *
     * @type {string}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    payerName: string;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    status: CustomerStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    credibility: CustomerCredibilityEnum;
    /**
     *
     * @type {string}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    tradingStartDate?: string;
    /**
     *
     * @type {boolean}
     * @memberof ShipToForCopySupplyLimitsDto
     */
    hasCustomizedSupplyLimits: boolean;
}
/**
 *
 * @export
 * @interface ShipToForSupplyLimitDto
 */
export interface ShipToForSupplyLimitDto {
    /**
     *
     * @type {number}
     * @memberof ShipToForSupplyLimitDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ShipToForSupplyLimitDto
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ShipToForSupplyLimitDto
     */
    acn: number;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof ShipToForSupplyLimitDto
     */
    status: CustomerStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ShipToForSupplyLimitDto
     */
    payerName: string;
    /**
     *
     * @type {number}
     * @memberof ShipToForSupplyLimitDto
     */
    payerAcn: number;
    /**
     *
     * @type {CustomerBranchDto}
     * @memberof ShipToForSupplyLimitDto
     */
    branch?: CustomerBranchDto;
    /**
     *
     * @type {SupplyLimitDto}
     * @memberof ShipToForSupplyLimitDto
     */
    supplyLimitDto?: SupplyLimitDto;
}
/**
 *
 * @export
 * @interface ShipToForSupplyLimitFilterDto
 */
export interface ShipToForSupplyLimitFilterDto {
    /**
     *
     * @type {number}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    productPackageId: number;
    /**
     *
     * @type {Array<number>}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    supplyLimitIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    name?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    branchIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    acn?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    payerName?: string;
    /**
     *
     * @type {string}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    payerAcn?: string;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof ShipToForSupplyLimitFilterDto
     */
    status?: CustomerStatusEnum;
}
/**
 *
 * @export
 * @interface ShipToListDto
 */
export interface ShipToListDto {
    /**
     *
     * @type {number}
     * @memberof ShipToListDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ShipToListDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ShipToListDto
     */
    acn: string;
    /**
     *
     * @type {string}
     * @memberof ShipToListDto
     */
    tin: string;
    /**
     *
     * @type {string}
     * @memberof ShipToListDto
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof ShipToListDto
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof ShipToListDto
     */
    zip: string;
    /**
     *
     * @type {CustomerStatusEnum}
     * @memberof ShipToListDto
     */
    status: CustomerStatusEnum;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof ShipToListDto
     */
    credibility?: CustomerCredibilityEnum;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof ShipToListDto
     */
    type?: CustomerTypeEnum;
}
/**
 *
 * @export
 * @interface ShipToPublicFormDto
 */
export interface ShipToPublicFormDto {
    /**
     *
     * @type {number}
     * @memberof ShipToPublicFormDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ShipToPublicFormDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface SimpleShipToDto
 */
export interface SimpleShipToDto {
    /**
     *
     * @type {number}
     * @memberof SimpleShipToDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof SimpleShipToDto
     */
    acn: number;
    /**
     *
     * @type {string}
     * @memberof SimpleShipToDto
     */
    name: string;
}
/**
 *
 * @export
 * @interface SpecialPriceDto
 */
export interface SpecialPriceDto {
    /**
     *
     * @type {number}
     * @memberof SpecialPriceDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof SpecialPriceDto
     */
    productPackageId: number;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    productPackageName: string;
    /**
     *
     * @type {SpecialPriceSourceEnum}
     * @memberof SpecialPriceDto
     */
    specialPriceSource: SpecialPriceSourceEnum;
    /**
     *
     * @type {number}
     * @memberof SpecialPriceDto
     */
    productPrice: number;
    /**
     *
     * @type {number}
     * @memberof SpecialPriceDto
     */
    productPackageMargin: number;
    /**
     *
     * @type {number}
     * @memberof SpecialPriceDto
     */
    specialPriceInclMargin: number;
    /**
     *
     * @type {number}
     * @memberof SpecialPriceDto
     */
    specialPriceMargin: number;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    validFrom: string;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    validTo: string;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    contractId: string;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    contractFrom?: string;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    contractTo?: string;
    /**
     *
     * @type {Array<SimpleShipToDto>}
     * @memberof SpecialPriceDto
     */
    applyToShipTos?: Array<SimpleShipToDto>;
    /**
     *
     * @type {boolean}
     * @memberof SpecialPriceDto
     */
    active: boolean;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    azNote?: string;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    createdOn: string;
    /**
     *
     * @type {string}
     * @memberof SpecialPriceDto
     */
    createdBy: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SpecialPriceSourceEnum {
    Customer = "CUSTOMER",
    InheritedFromPayer = "INHERITED_FROM_PAYER",
}
/**
 *
 * @export
 * @interface SupplyLimitDto
 */
export interface SupplyLimitDto {
    /**
     *
     * @type {number}
     * @memberof SupplyLimitDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitDto
     */
    tierNumber: number;
    /**
     *
     * @type {boolean}
     * @memberof SupplyLimitDto
     */
    isDefault: boolean;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitDto
     */
    dailyLimit: number;
}
/**
 *
 * @export
 * @interface SupplyLimitSuggestionDto
 */
export interface SupplyLimitSuggestionDto {
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    shipToId: number;
    /**
     *
     * @type {string}
     * @memberof SupplyLimitSuggestionDto
     */
    shipToName: string;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    shipToAcn: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    productPackageId: number;
    /**
     *
     * @type {string}
     * @memberof SupplyLimitSuggestionDto
     */
    productPackageName: string;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    currentTierNumber: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    currentTierMonthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    averageRequestedAmount: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    approvedEmergencyOrdersCount: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    suggestedTierId?: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    suggestedTierNumber?: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitSuggestionDto
     */
    suggestedTierMonthlyLimit?: number;
}
/**
 *
 * @export
 * @interface SupplyLimitWithPackageAndMarginDto
 */
export interface SupplyLimitWithPackageAndMarginDto {
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    productPackageId: number;
    /**
     *
     * @type {string}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    productPackageName: string;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    margin: number;
    /**
     *
     * @type {MarginSourceEnum}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    marginSource: MarginSourceEnum;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    tierNumber: number;
    /**
     *
     * @type {boolean}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    isDefault: boolean;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    dailyLimit: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    dailyVolumeAdjustment?: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithPackageAndMarginDto
     */
    monthlyVolumeAdjustment?: number;
}
/**
 *
 * @export
 * @interface SupplyLimitWithShipToCountDto
 */
export interface SupplyLimitWithShipToCountDto {
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithShipToCountDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithShipToCountDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithShipToCountDto
     */
    tierNumber: number;
    /**
     *
     * @type {boolean}
     * @memberof SupplyLimitWithShipToCountDto
     */
    isDefault: boolean;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithShipToCountDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithShipToCountDto
     */
    dailyLimit: number;
    /**
     *
     * @type {number}
     * @memberof SupplyLimitWithShipToCountDto
     */
    shipToCount: number;
}
/**
 *
 * @export
 * @interface SupplyLimitsForShipToAndProductPackageDto
 */
export interface SupplyLimitsForShipToAndProductPackageDto {
    /**
     *
     * @type {number}
     * @memberof SupplyLimitsForShipToAndProductPackageDto
     */
    shipToMargin?: number;
    /**
     *
     * @type {SupplyLimitDto}
     * @memberof SupplyLimitsForShipToAndProductPackageDto
     */
    currentSupplyLimit: SupplyLimitDto;
    /**
     *
     * @type {Array<SupplyLimitDto>}
     * @memberof SupplyLimitsForShipToAndProductPackageDto
     */
    availableSupplyLimits: Array<SupplyLimitDto>;
}
/**
 *
 * @export
 * @interface UpdateCredibilityDto
 */
export interface UpdateCredibilityDto {
    /**
     *
     * @type {number}
     * @memberof UpdateCredibilityDto
     */
    customerId: number;
    /**
     *
     * @type {CustomerCredibilityEnum}
     * @memberof UpdateCredibilityDto
     */
    credibility: CustomerCredibilityEnum;
}
/**
 *
 * @export
 * @interface UpdateMarginsForPayerDto
 */
export interface UpdateMarginsForPayerDto {
    /**
     *
     * @type {Array<number>}
     * @memberof UpdateMarginsForPayerDto
     */
    productPackageIds?: Array<number>;
    /**
     *
     * @type {ProductBrandWithMarginsFilterDto}
     * @memberof UpdateMarginsForPayerDto
     */
    filterDto: ProductBrandWithMarginsFilterDto;
    /**
     *
     * @type {number}
     * @memberof UpdateMarginsForPayerDto
     */
    margin?: number;
}
/**
 *
 * @export
 * @interface UpdateNoteDto
 */
export interface UpdateNoteDto {
    /**
     *
     * @type {number}
     * @memberof UpdateNoteDto
     */
    customerId: number;
    /**
     *
     * @type {string}
     * @memberof UpdateNoteDto
     */
    note: string;
}
/**
 *
 * @export
 * @interface UpdateProductBrandDto
 */
export interface UpdateProductBrandDto {
    /**
     *
     * @type {number}
     * @memberof UpdateProductBrandDto
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof UpdateProductBrandDto
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdateProductBrandDto
     */
    active: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdateProductBrandDto
     */
    archived: boolean;
}
/**
 *
 * @export
 * @interface UpdateProductPackageDto
 */
export interface UpdateProductPackageDto {
    /**
     *
     * @type {number}
     * @memberof UpdateProductPackageDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof UpdateProductPackageDto
     */
    productBrandId: number;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPackageDto
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPackageDto
     */
    sapCode: string;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPackageDto
     */
    agentCode: string;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPackageDto
     */
    eanCode?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPackageDto
     */
    suklCode: string;
    /**
     *
     * @type {number}
     * @memberof UpdateProductPackageDto
     */
    margin: number;
    /**
     *
     * @type {boolean}
     * @memberof UpdateProductPackageDto
     */
    active: boolean;
}
/**
 *
 * @export
 * @interface UpdateProductPriceDto
 */
export interface UpdateProductPriceDto {
    /**
     *
     * @type {number}
     * @memberof UpdateProductPriceDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof UpdateProductPriceDto
     */
    productPackageId: number;
    /**
     *
     * @type {number}
     * @memberof UpdateProductPriceDto
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPriceDto
     */
    validFrom: string;
    /**
     *
     * @type {string}
     * @memberof UpdateProductPriceDto
     */
    validTo?: string;
}
/**
 *
 * @export
 * @interface UpdateSpecialPriceDto
 */
export interface UpdateSpecialPriceDto {
    /**
     *
     * @type {number}
     * @memberof UpdateSpecialPriceDto
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof UpdateSpecialPriceDto
     */
    specialPriceInclMargin: number;
    /**
     *
     * @type {string}
     * @memberof UpdateSpecialPriceDto
     */
    validFrom: string;
    /**
     *
     * @type {string}
     * @memberof UpdateSpecialPriceDto
     */
    validTo?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateSpecialPriceDto
     */
    contractId: string;
    /**
     *
     * @type {string}
     * @memberof UpdateSpecialPriceDto
     */
    contractFrom?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateSpecialPriceDto
     */
    contractTo?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof UpdateSpecialPriceDto
     */
    applyToShipTos?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof UpdateSpecialPriceDto
     */
    azNote?: string;
}
/**
 *
 * @export
 * @interface UpdateSupplyLimitAndMarginForShipToDto
 */
export interface UpdateSupplyLimitAndMarginForShipToDto {
    /**
     *
     * @type {number}
     * @memberof UpdateSupplyLimitAndMarginForShipToDto
     */
    shipToId: number;
    /**
     *
     * @type {number}
     * @memberof UpdateSupplyLimitAndMarginForShipToDto
     */
    newSupplyLimitId: number;
    /**
     *
     * @type {number}
     * @memberof UpdateSupplyLimitAndMarginForShipToDto
     */
    shipToMargin?: number;
}
/**
 *
 * @export
 * @interface UpdateSupplyLimitDto
 */
export interface UpdateSupplyLimitDto {
    /**
     *
     * @type {number}
     * @memberof UpdateSupplyLimitDto
     */
    id: number;
    /**
     *
     * @type {boolean}
     * @memberof UpdateSupplyLimitDto
     */
    isDefault: boolean;
    /**
     *
     * @type {number}
     * @memberof UpdateSupplyLimitDto
     */
    monthlyLimit: number;
    /**
     *
     * @type {number}
     * @memberof UpdateSupplyLimitDto
     */
    dailyLimit: number;
    /**
     *
     * @type {boolean}
     * @memberof UpdateSupplyLimitDto
     */
    _default?: boolean;
}
/**
 *
 * @export
 * @interface UpdateTradingTermsDto
 */
export interface UpdateTradingTermsDto {
    /**
     *
     * @type {number}
     * @memberof UpdateTradingTermsDto
     */
    customerId: number;
    /**
     *
     * @type {number}
     * @memberof UpdateTradingTermsDto
     */
    creditLimit?: number;
    /**
     *
     * @type {PaymentDueEnum}
     * @memberof UpdateTradingTermsDto
     */
    paymentDue: PaymentDueEnum;
    /**
     *
     * @type {InvoicingFrequencyEnum}
     * @memberof UpdateTradingTermsDto
     */
    invoicingFrequency: InvoicingFrequencyEnum;
    /**
     *
     * @type {boolean}
     * @memberof UpdateTradingTermsDto
     */
    setTradingTermsToShipTos: boolean;
    /**
     *
     * @type {CustomerTypeEnum}
     * @memberof UpdateTradingTermsDto
     */
    type?: CustomerTypeEnum;
}
