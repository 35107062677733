import type { FC } from "react";

import { FileMimeType, url, useDownloadFileMutation } from "src/api";
import { Button, DateInput, DateInputProps, Modal, ModalButtonLayout } from "src/components";
import { Form, InputField } from "src/form";
import { useModal } from "src/modal";
import { useTranslation } from "src/translations";
import { formatDateForBE } from "src/utils";
import { PRODUCT_PRICE_LIST_MODAL_ID } from "../constants";

type PriceListFormData = { generateForDate: Date };

export const PriceListForm: FC = () => {
    const [t] = useTranslation();
    const [isModalOpened, , closeModal] = useModal(PRODUCT_PRICE_LIST_MODAL_ID);

    const [downloadFile] = useDownloadFileMutation();

    const initialValues = {
        generateForDate: new Date(),
    };

    const onSuccess = () => {
        closeModal();
        window.location.href = "mailto:";
    };

    return (
        <Modal title={t("prices.priceListForm.title")} maxWidth="450px" opened={isModalOpened} onClose={closeModal}>
            <Form<PriceListFormData>
                initialValues={initialValues}
                onSubmit={(values: PriceListFormData) =>
                    downloadFile({
                        url: `${url.PRODUCT_PRICES_PRODUCT_PRICE_LIST}?specificDate=${formatDateForBE(values.generateForDate)}`,
                        filename: `Price list_${formatDateForBE(values.generateForDate)}`,
                        fileMimeType: FileMimeType.Xls,
                    })
                }
                onSuccess={onSuccess}
            >
                {({ isValid }) => (
                    <>
                        <InputField<DateInputProps["value"], DateInputProps>
                            name="generateForDate"
                            input={DateInput}
                            required
                            inputProps={{
                                label: t("prices.priceListForm.forDate.label"),
                                placeholder: t("common.datePlaceholder"),
                                maxWidth: "195px",
                            }}
                        />
                        <ModalButtonLayout>
                            <Button disabled={!isValid} type="submit">
                                {t("common.create")}
                            </Button>
                            <Button type="button" onClick={closeModal} variant="outline">
                                {t("common.cancel")}
                            </Button>
                        </ModalButtonLayout>
                    </>
                )}
            </Form>
        </Modal>
    );
};
