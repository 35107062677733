import { UnstyledButton } from "@mantine/core";
import type { ReactElement } from "react";
import styled, { css } from "styled-components";

import { maxWidthBreakpoint } from "src/theme";
import { Flex } from "../layouts";
import { TabContext } from "./tabContext";

type TabsProps<TabValueEnum = string> = {
    activeTab: TabValueEnum;
    tabs: Array<{ tabValue: TabValueEnum; label: string }>;
    children: ReactElement | ReactElement[];
    onTabChange: (activeTab: TabValueEnum) => void;
    rightSection?: ReactElement | ReactElement[];
};

export const Tabs = <TabValueEnum extends string>({
    activeTab,
    tabs,
    onTabChange,
    children,
    rightSection,
}: TabsProps<TabValueEnum>): ReactElement => (
    <TabContext.Provider value={activeTab}>
        <Layout justifyContent="space-between">
            <TabList>
                {tabs.map(({ label, tabValue }) => (
                    <Tab key={tabValue} onClick={() => onTabChange(tabValue)} $active={activeTab === tabValue}>
                        {label}
                    </Tab>
                ))}
            </TabList>
            <RightSectionLayout>{rightSection}</RightSectionLayout>
        </Layout>

        {children}
    </TabContext.Provider>
);

const Layout = styled(Flex)`
    margin-bottom: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.color.neutral200};

    ${maxWidthBreakpoint.tablet(css`
        flex-direction: column;
        border-bottom: none;
        margin-bottom: 0.5rem;
    `)};
`;

const RightSectionLayout = styled.div`
    display: inline-flex;

    ${maxWidthBreakpoint.tablet(css`
        align-self: end;
        margin-top: 1rem;
    `)};
`;

const TabList = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;

    ${maxWidthBreakpoint.desktopSmall(css`
        display: flex;
        flex-wrap: wrap;
        grid-auto-flow: unset;
        border-bottom: none;
    `)};

    ${({ theme }) =>
        maxWidthBreakpoint.tablet(css`
            margin-bottom: 1rem;
            border-bottom: 1px solid ${theme.color.neutral200};
        `)};

    ${maxWidthBreakpoint.mobile(css`
        display: grid;
        grid-template-columns: 1fr 1fr;
    `)};
`;

const Tab = styled(UnstyledButton)<{ $active: boolean }>`
    &.mantine-UnstyledButton-root {
        padding: 0.8rem 1.6rem;
        font-size: 1.6rem;
        color: ${({ theme }) => theme.color.supportNavy400};
        background-color: transparent;
        transition: background-color 0.1s ease;
        border-radius: ${({ theme }) => theme.radius.default} ${({ theme }) => theme.radius.default} 0 0;

        ${({ theme }) =>
            maxWidthBreakpoint.desktopSmall(css`
                border-radius: ${theme.radius.default} ${theme.radius.default} ${theme.radius.default} ${theme.radius.default};
            `)};

        ${({ $active }) =>
            $active &&
            css`
                border-color: ${({ theme }) => theme.color.neutral200};
                background-color: ${({ theme }) => theme.color.neutral200};
                color: ${({ theme }) => theme.color.supportNavy500};
            `}
    }
`;
