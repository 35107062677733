import type { FC } from "react";

import { useApproveEmergencyOrdersMutation, useLazyGetEmergencyOrderMinimalPossibleAmountQuery } from "src/api";
import {
    Button,
    Flex,
    LoadingOverlay,
    Modal,
    ModalButtonLayout,
    NumberInput,
    NumberInputProps,
    Text,
    Textarea,
    TextareaProps,
} from "src/components";
import { Form, InputField } from "src/form";
import { useModal } from "src/modal";
import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import type { ApproveEmergencyOrderDto } from "src/types";
import { useAppDispatch } from "src/utils";
import { APPROVE_EMERGENCY_ORDERS_MODAL } from "./constants";
import { groupedTableModule } from "./groupedTable";
import { tableModule } from "./table";

type ApproveWithXOrdersFormProps = Readonly<{
    selectedIds: number[];
}>;
export const ApproveWithXOrdersForm: FC<ApproveWithXOrdersFormProps> = ({ selectedIds }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isModalOpened, openModal, closeModal] = useModal(APPROVE_EMERGENCY_ORDERS_MODAL);
    const [approve] = useApproveEmergencyOrdersMutation();
    const [getMinimalPossibleAmount, { data: minimalPossibleAmount, isLoading }] = useLazyGetEmergencyOrderMinimalPossibleAmountQuery();

    const onSuccess = () => {
        dispatch(tableModule.resetTableSelectionAction);
        dispatch(groupedTableModule.resetTableSelectionAction);
        closeModal();
    };

    return (
        <>
            <Button
                disabled={selectedIds.length === 0}
                type="button"
                onClick={() => {
                    openModal();
                    getMinimalPossibleAmount(selectedIds);
                }}
            >
                {t("dashboard.emergencyOrders.approveWithXOrders")}
            </Button>
            <Modal title={t("dashboard.emergencyOrders.approveWithXOrdersModal.title")} opened={isModalOpened} onClose={onSuccess}>
                <LoadingOverlay loading={isLoading} />
                {minimalPossibleAmount && (
                    <Form<ApproveEmergencyOrderDto>
                        initialValues={{ emergencyOrderProductPackageIds: selectedIds, approvedAmount: minimalPossibleAmount, azNote: "" }}
                        // Please be careful with copying this prop to other forms, it has impact to performance,
                        // it has to be here, because form will stay mounted if modal is closed
                        enableReinitialize
                        onSubmit={approve}
                        onSuccess={onSuccess}
                        loadingMessage={t("dashboard.emergencyOrders.loadingApproveBulkMessage")}
                        successMessage={t("dashboard.emergencyOrders.successApproveBulkMessage")}
                    >
                        {({ values }) => (
                            <>
                                <Text color={Color.supportNavy500} weight={500}>
                                    {t("dashboard.emergencyOrders.approveWithXOrdersModal.approveFor")}
                                </Text>
                                <Flex alignItems="end" gap="1rem">
                                    <InputField<NumberInputProps["value"], NumberInputProps>
                                        name="approvedAmount"
                                        type="number"
                                        input={NumberInput}
                                        validate={(value) =>
                                            value > minimalPossibleAmount
                                                ? "dashboard.emergencyOrders.approveWithXOrdersModal.inputError"
                                                : undefined
                                        }
                                        inputProps={{
                                            maxWidth: "90px",
                                            min: 0,
                                        }}
                                    />
                                </Flex>
                                <InputField<TextareaProps["value"], TextareaProps>
                                    name="azNote"
                                    input={Textarea}
                                    inputProps={{
                                        label: t("dashboard.emergencyOrders.approveWithXOrdersModal.note"),
                                    }}
                                />
                                <ModalButtonLayout>
                                    <Button type="submit" disabled={(values?.approvedAmount ?? 0) > minimalPossibleAmount}>
                                        {t("dashboard.emergencyOrders.approveWithXOrders")}
                                    </Button>
                                    <Button type="button" onClick={closeModal} variant="outline">
                                        {t("common.cancel")}
                                    </Button>
                                </ModalButtonLayout>
                            </>
                        )}
                    </Form>
                )}
            </Modal>
        </>
    );
};
