import type { TFunction } from "i18next";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";

import { useLazyGetCustomersWithSameAddressOrAcnQuery } from "src/api";
import { createSearchParamsAsString, routes } from "src/routes";
import { useTranslation } from "src/translations";
import type { CustomerDto, CustomerTypeEnum, InvoicingFrequencyEnum, PaymentDueEnum, ShipToForCopySupplyLimitsDto } from "src/types";
import { cleanObject, EMPTY_ARRAY, FALSE_STRING, isEmpty, TRUE_STRING } from "src/utils";
import { CopyCustomerWidgetComponent } from "../components";
import {
    ACN_PARAM,
    ACTIVE_PARAM,
    INVOICING_FREQUENCY_PARAM,
    IS_EXISTING_SHIP_TO,
    PAYMENT_DUE_PARAM,
    PROPAGATE_SPECIAL_PRICES,
    START_POINT_PARAM,
    TYPE,
} from "../constants";
import type { StartPointEnum } from "./constants";

type CopyCustomerWidgetProps = {
    customer: CustomerDto;
    paymentDue: PaymentDueEnum;
    invoicingFrequency: InvoicingFrequencyEnum;
    active: boolean;
    startPoint: StartPointEnum;
    type?: CustomerTypeEnum;
    propagateSpecialPrices: boolean;
};

export const CopyCustomerWidget: FC<CopyCustomerWidgetProps> = ({
    customer,
    paymentDue,
    active,
    invoicingFrequency,
    startPoint,
    type,
    propagateSpecialPrices,
}) => {
    const [acnForSearch, setAcnForSearch] = useState<string>("");
    const { t } = useTranslation();
    const [trigger, { data, isLoading, isFetching, isSuccess }] = useLazyGetCustomersWithSameAddressOrAcnQuery();

    useEffect(() => {
        trigger({ targetShipToId: customer.id });
    }, [customer.id, trigger]);

    const onSearchByAcn = (acn: string) => {
        setAcnForSearch(acn);
        trigger({ targetShipToId: customer.id, acn });
    };

    const notFoundText = isEmpty(acnForSearch)
        ? t("customers.approveForm.widget.notFound")
        : t("customers.approveForm.widget.notFoundWithAcn", { acn: acnForSearch });

    const foundText = getFoundText(t, acnForSearch, data);

    const foundLink = useMemo(
        () =>
            `${routes.copyTiers.fillPathParams({ targetShipToId: customer.id.toString() })}?${createSearchParamsAsString(
                cleanObject({
                    [ACN_PARAM]: acnForSearch === "" ? undefined : acnForSearch,
                    [PAYMENT_DUE_PARAM]: paymentDue,
                    [INVOICING_FREQUENCY_PARAM]: invoicingFrequency,
                    [ACTIVE_PARAM]: active ? TRUE_STRING : FALSE_STRING,
                    [START_POINT_PARAM]: startPoint,
                    [TYPE]: type,
                    [PROPAGATE_SPECIAL_PRICES]: propagateSpecialPrices,
                    [IS_EXISTING_SHIP_TO]: FALSE_STRING,
                }),
            )}`,
        [customer.id, acnForSearch, paymentDue, invoicingFrequency, active, startPoint, type, propagateSpecialPrices],
    );

    return (
        <CopyCustomerWidgetComponent
            isSearching={isLoading || isFetching}
            onSearchByAcn={onSearchByAcn}
            sameCustomers={isSuccess ? data : EMPTY_ARRAY}
            notFoundText={notFoundText}
            foundText={foundText}
            foundLink={foundLink}
        />
    );
};

const getFoundText = (t: TFunction, acnForSearch: string, sameCustomers?: ReadonlyArray<ShipToForCopySupplyLimitsDto>): string => {
    if (!isEmpty(acnForSearch)) {
        return t("customers.approveForm.widget.foundWithAcn", { acn: acnForSearch });
    }
    if (sameCustomers && sameCustomers.length === 1) {
        return t("customers.approveForm.widget.found", { credibility: sameCustomers[0].credibility, status: sameCustomers[0].status });
    }

    return t("customers.approveForm.widget.moreCustomersFound");
};
